/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Card, Banner, Input, Button} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    pre: "pre",
    code: "code",
    p: "p",
    hr: "hr",
    strong: "strong",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "default-modal",
    style: {
      position: "relative"
    }
  }, "Default Modal", React.createElement(_components.a, {
    href: "#default-modal",
    "aria-label": "default modal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 400\n---\n<Modal\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\tonClose={() => alert(\"closing\")}\n\ttitle=\"Grand Moff Tarkin\"\n\tfooter={<Button primary>Close</Button>}\n\tportal={false}\n>\n\tDon't worry, we'll deal with your rebel friends soon enough!\n</Modal>\n")), "\n", React.createElement(_components.h1, {
    id: "size",
    style: {
      position: "relative"
    }
  }, "Size", React.createElement(_components.a, {
    href: "#size",
    "aria-label": "size permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Modals have four sizes depending on the width of content that needs to be displayed. Medium is the default size."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State\n\tinitial={{\n\t\topen: false,\n\t}}\n>\n\t{([state, setState]) => (\n\t\t<div>\n\t\t\t<ButtonGroup>\n\t\t\t\t{[\n\t\t\t\t\tModal.Sizes.XS,\n\t\t\t\t\tModal.Sizes.S,\n\t\t\t\t\tModal.Sizes.M,\n\t\t\t\t\tModal.Sizes.L,\n\t\t\t\t].map((width, index) => (\n\t\t\t\t\t<Button\n\t\t\t\t\t\tkey={index}\n\t\t\t\t\t\tonClick={() =>\n\t\t\t\t\t\t\tsetState({ modalWidth: width, open: true })\n\t\t\t\t\t\t}\n\t\t\t\t\t>\n\t\t\t\t\t\t<span className=\"tt-uppercase m-r-half\">{width}</span>{' '}\n\t\t\t\t\t\tModal\n\t\t\t\t\t</Button>\n\t\t\t\t))}\n\t\t\t</ButtonGroup>\n\t\t\t<Modal\n\t\t\t\topen={state.open}\n\t\t\t\tsize={state.modalWidth}\n\t\t\t\tonClose={() => setState({ open: false })}\n\t\t\t\ttitle={`${state.modalWidth} Modal`}\n\t\t\t\tfooter={\n\t\t\t\t\t<Button primary onClick={() => setState({ open: false })}>\n\t\t\t\t\t\tClose\n\t\t\t\t\t</Button>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\tDon't worry, we'll deal with your rebel friends soon enough!\n\t\t\t</Modal>\n\t\t</div>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "scrolling-content",
    style: {
      position: "relative"
    }
  }, "Scrolling Content", React.createElement(_components.a, {
    href: "#scrolling-content",
    "aria-label": "scrolling content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When the content of the modal is larger than the screen supports, it becomes scrollable with consistent spacing between the screen edge and the modal."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 400\n---\n<Modal\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\tonClose={() => alert(\"closing\")}\n\ttitle=\"Grand Moff Tarkin\"\n\tfooter={<Button primary>Close</Button>}\n\tportal={false}\n>\n\t<p style={{marginTop: 0}}>\n\t\tChia cillum etsy pabst, in paleo fashion axe.\n\t\tGastropub pariatur tilde wayfarers chia laboris.\n\t\tSalvia cred franzen chambray cillum slow-carb.\n\t\tBeard letterpress distillery, knausgaard readymade YOLO in.\n\t\tAesthetic ea aliqua, blog vaporware kombucha gluten-free art party VHS pariatur cray raclette.\n\t</p>\n\t<p>\n\t\tPop-up kale chips four dollar toast gastropub you probably haven't heard of them prism tote bag.\n\t\tPaleo thundercats godard glossier +1, iceland anim.\n\t\tReadymade sriracha occaecat, crucifix bicycle rights retro seitan exercitation craft beer kale\n\t\tchips minim.\n\t\tDo post-ironic wayfarers, seitan etsy small batch hammock green juice hexagon whatever hoodie\n\t\tipsum fashion axe copper mug.\n\t\tFingerstache put a bird on it palo santo craft beer.\n\t</p>\n\t<p>\n\t\tAdaptogen officia cred ut enamel pin.\n\t\tMan bun fixie blue bottle minim proident franzen raw denim fanny pack, church-key edison bulb\n\t\tbutcher lumbersexual vaporware ethical YOLO.\n\t\tMlkshk elit austin succulents live-edge poke esse pork belly williamsburg consectetur\n\t\thelvetica craft beer put a bird on it pop-up aliqua.\n\t\tViral irure synth laboris laborum.\n\t</p>\n\t<p>\n\t\tUt blue bottle activated charcoal pickled meh.\n\t\tForage esse occupy artisan aliquip scenester biodiesel street art actually truffaut succulents\n\t\tart party yr nulla ethical.\n\t\tHell of waistcoat aliquip gochujang sunt, fashion axe sed ut photo booth cronut.\n\t\tKogi tattooed semiotics cold-pressed adipisicing taiyaki.\n\t</p>\n</Modal>\n")), "\n", React.createElement(_components.h1, {
    id: "non-closable-modals",
    style: {
      position: "relative"
    }
  }, "Non-closable Modals", React.createElement(_components.a, {
    href: "#non-closable-modals",
    "aria-label": "non closable modals permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For mandatory workflows and notifications, we can also remove the close functionality. The close button in the Modal Header does not appear and clicking on the backdrop doesn't do anything."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([open, setOpen]) => (\n\t\t<>\n\t\t\t<Button onClick={() => setOpen(true)}>Open</Button>\n\t\t\t<Modal\n\t\t\t\topen={open}\n\t\t\t\ttitle=\"Accept Estimate\"\n\t\t\t\tfooter={\n\t\t\t\t\t<Button primary onClick={() => setOpen(false)}>\n\t\t\t\t\t\tI Accept\n\t\t\t\t\t</Button>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\t<Form>\n\t\t\t\t\t<Form.Input value=\"John Smith\" />\n\t\t\t\t</Form>\n\t\t\t\t<p>\n\t\t\t\t\tI understand this is a legal representation of my signature.\n\t\t\t\t</p>\n\t\t\t</Modal>\n\t\t</>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "footer-styles",
    style: {
      position: "relative"
    }
  }, "Footer Styles", React.createElement(_components.a, {
    href: "#footer-styles",
    "aria-label": "footer styles permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The Modal Footer can have a few layouts depending on the needs of the Modal. Usually they fall into one of these four layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen={open}\n\tfocusTrapOptions={{ disabled: true }}\n\tfooter={<Button primary>Save</Button>}\n\tportal={false}\n>\n\tSingle Right Aligned Button\n</Modal>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen={open}\n\tfocusTrapOptions={{ disabled: true }}\n\tfooter={<ButtonGroup>\n\t\t<Button>Cancel</Button>\n\t\t<Button primary>Save</Button>\n\t</ButtonGroup>}\n\tportal={false}\n>\n\tDouble Right Aligned Buttons\n</Modal>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen={open}\n\tfocusTrapOptions={{ disabled: true }}\n\tfooter={<Button>Close</Button>}\n\tfooterAlign=\"center\"\n\tportal={false}\n>\n\tCentered Button\n</Modal>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 200\n---\n<Modal\n\topen={open}\n\tfocusTrapOptions={{ disabled: true }}\n\tfooter={<React.Fragment>\n\t\t<Button>Cancel</Button>\n\t\t<Button primary>Save</Button>\n\t</React.Fragment>}\n\tfooterAlign=\"space-between\"\n\tportal={false}\n>\n\tSplit Buttons\n</Modal>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.strong, null, "Modal"), " should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Be used to display critical information or workflows that do not merit full pages"), "\n", React.createElement(_components.li, null, "Include clear call-to-actions for the user to proceed, cancel, or close"), "\n", React.createElement(_components.li, null, "Be used sparingly because they are purposefully interruptive"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "write-titles-as-verbs",
    style: {
      position: "relative"
    }
  }, "Write titles as verbs", React.createElement(_components.a, {
    href: "#write-titles-as-verbs",
    "aria-label": "write titles as verbs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Titles should have a clear verb + noun question or statement."), "\n", React.createElement(_components.p, null, "Follow the content guidelines for ", React.createElement(_components.a, {
    href: "https://polaris.shopify.com/content/actionable-language#headings-and-subheadings"
  }, "headings and subheadings"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement("ul", null, React.createElement("li", null, "Edit customer information"), React.createElement("li", null, "Delete message?"), React.createElement("li", null, "Discard unsaved changes?")))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement("ul", null, React.createElement("li", null, "Edit the service agreement for this customer"), React.createElement("li", null, "Are you sure you want to remove the invoice?"), React.createElement("li", null, "Delete?")))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "make-body-content-concise-and-actionable",
    style: {
      position: "relative"
    }
  }, "Make body content concise and actionable", React.createElement(_components.a, {
    href: "#make-body-content-concise-and-actionable",
    "aria-label": "make body content concise and actionable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use imperative verbs when telling users what they need to know or do. Don’t use permissive language like \"you can\"."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement("ul", null, React.createElement("li", null, "Notification emails will be sent to this address."), React.createElement("li", null, "This cannot be undone.")))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement("ul", null, React.createElement("li", null, "You can edit the email address where emails will be sent."), React.createElement("li", null, "Are you sure you want to delete this job? You can’t reverse this.")))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "buttons-should-be-clear-predictable-and-action-oriented",
    style: {
      position: "relative"
    }
  }, "Buttons should be clear, predictable, and action-oriented", React.createElement(_components.a, {
    href: "#buttons-should-be-clear-predictable-and-action-oriented",
    "aria-label": "buttons should be clear predictable and action oriented permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Users should be able to predict what will happen when they click a button."), "\n", React.createElement(_components.p, null, "Lead with a strong verb that encourages action. Use a verb/noun pair on actions in most cases. Common actions like Save, Close, or Cancel do not require an accompanying noun."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    primary: true
  }, "Create Order"), React.createElement(Button, {
    primary: true
  }, "Print Label"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    primary: true
  }, "New invoice"), React.createElement(Button, {
    primary: true
  }, "Schedule"))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To completely hide the context of the page, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/takeover/"
  }, "Takeover")), " component."), "\n", React.createElement(_components.li, null, "For critical information that requires a specific decision, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/dialog"
  }, "Dialog")), " component."), "\n", React.createElement(_components.li, null, "For informative or basic actions that do not interrupt the user flow, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/popover"
  }, "Popover")), "."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { Modal } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
